// import Cookies from "js-cookie";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  setLocalStorage,
  getFromLocalStorage,
  clearLocalStorage,
  setSessionStorage,
  clearSessionStorage,
  getFromSessionStorage,
} from "../utilities/helpers";

const AuthContext = createContext({
  user: null,
});

export const AuthContextProvider = ({ children }) => {
  const [jwt, setJwt] = useState(null);
  const [user, setUser] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState("el-GR");

  const handleLogout = useCallback(() => {
    //* Remove cookies
    clearLocalStorage();
    clearSessionStorage();
    setUser(null);
    setJwt(null);
  }, []);

  const handleLogin = useCallback((response, rememberMe = false) => {
    if (!response) return null;
    setUser(response?.data?.user);
    setJwt(response?.data?.jwt);

    if (rememberMe) {
      setLocalStorage("user", response?.data?.user, true);
      setLocalStorage("jwt", response?.data?.jwt, false);
    } else {
      setSessionStorage("user", response?.data?.user, true);
      setSessionStorage("jwt", response?.data?.jwt, false);
    }
  }, []);

  const checkLoginStatus = useCallback(async () => {
    // console.log("checkLoginStatus", user, "user", jwt, "jwt");
    setUser(
      getFromSessionStorage("user", true) ||
        getFromLocalStorage("user", true) ||
        null
    );
    setJwt(
      getFromSessionStorage("jwt", false) ||
        getFromLocalStorage("jwt", false) ||
        null
    );
  }, []);

  useEffect(() => {
    checkLoginStatus();
  }, [checkLoginStatus]);

  const value = useMemo(
    () => ({
      user,
      jwt,
      checkLoginStatus,
      handleLogout,
      handleLogin,
      currentLanguage,
      setCurrentLanguage,
    }),
    [
      user,
      jwt,
      checkLoginStatus,
      handleLogout,
      handleLogin,
      currentLanguage,
      setCurrentLanguage,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within AuthContextProvider");
  }

  return context;
};

export default AuthContext;
