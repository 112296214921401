export const setLocalStorage = (name, data, isJSON = false) => {
  if (isJSON) {
    return localStorage.setItem(name, JSON.stringify(data));
  }
  return localStorage.setItem(name, data);
};

export const getFromLocalStorage = (name, isJSON = false) => {
  const item = localStorage.getItem(name);
  if (item === null || item === "undefined") {
    return null; // or any other default value you prefer
  }
  if (isJSON) {
    try {
      return JSON.parse(item);
    } catch (error) {
      console.error(
        `Error parsing JSON from localStorage for key "${name}":`,
        error
      );
      return null;
    }
  }
  return item;
};

export const clearLocalStorage = () => {
  localStorage.clear();
  return true;
};
export const removeFromLocalStorage = (name) => {
  localStorage.removeItem(name);
  return true;
};
export const setSessionStorage = (name, data, isJSON = false) => {
  if (isJSON) {
    return sessionStorage.setItem(name, JSON.stringify(data));
  }
  return sessionStorage.setItem(name, data);
};

export const getFromSessionStorage = (name, isJSON = false) => {
  const item = sessionStorage.getItem(name);
  if (item === null || item === "undefined") {
    return null; // or any other default value you prefer
  }
  if (isJSON) {
    try {
      return JSON.parse(item);
    } catch (error) {
      console.error(
        `Error parsing JSON from sessionStorage for key "${name}":`,
        error
      );
      return null;
    }
  }
  return item;
};

export const clearSessionStorage = () => {
  sessionStorage.clear();
  return true;
};
export const removeFromSessionStorage = (name) => {
  sessionStorage.removeItem(name);
  return true;
};
